<template>
  <div class="listThird">
    <div class="image">
      <img :src="indexImg" style="width: 100%; height: 100%" />
    </div>
    <div class="layout">
      <div class="contentlistThird">
        <div class="listHd clearfloat">
          <div class="fl listHdGrop">
            {{$t('m.listPageOption')}}：<a href="/" style="font-weight: 700; color: #303133;">{{$t('m.listPageHome')}}</a> > 
            <!-- <span v-if="viewSlideHdParent"><a :href="slideHdParentUrl">{{slideHdParentName}}</a> ></span> 
            <span>&nbsp;{{slideHdName}} ></span> -->
            <a href="/special"> <span style="font-weight: 700; color: #303133;">&nbsp;专题专栏 ></span> </a>
          <span style="color: #0162b3;">&nbsp;专栏列表</span>
          </div>
        </div>
        <div class="main" >
          <dl v-for="(item, index) in listData" :key="index">
            <dt>
              <span>{{ dayjs(item.publishDate).format('DD') }}</span> 
              <p>{{ dayjs(item.publishDate).format('YYYY-MM') }}</p>
            </dt> 
            <dd>
              <div class="main_right_top">
                <a v-if="item.url && item.url !== ''" target="_blank" :href="item.url" style="width: 100%;">
                  <p>
                    {{ item.title |ellipsis2 }}
                  </p> 
                  <span>...</span>
                </a>
                <a v-else :href="`/detail/${item.entTChannelUuid}/${item.entTArticleUuid}`" style="width: 100%;">
                  <p>
                    {{ item.title |ellipsis2 }}
                  </p> 
                  <span>...</span>
                </a>
              </div> 
              <p class="main_right_text">
                {{item.artDesc | ellipsis}}
              </p> 
              <div class="main_right_foot">来源：
                <span>{{item.author}}</span>
              </div>
            </dd>
          </dl>
        </div>
        <div class="listpagination" v-if="listData.length!=0">
          <el-pagination 
            background 
            layout="prev, pager, next" 
            :current-page="pageNum" 
            :page-size="pageSize" 
            :total="total" 
            :pager-count="11" 
            @current-change="currentChange" 
          >
          </el-pagination>
        </div>
        <div v-if="listData.length==0 || this.noempty ">
          <el-empty></el-empty>
        </div>
      </div>    
    </div>
  </div>
</template>

<script>
  import {
    getChildChannel,
    getChannelInfoNew,
    articles,
    // getCookie,
    // getAllArtListByParentUuid
  } from '@/api/common'

  export default {
    name: 'list',
    data() {
      return {
        isMenuShow:true,
        isthird: false,
        isClickid: '',
        isClickid2: '',
        slideHdName1: '',
        slideHdName2: '',
        slideHdTitle: '',
        viewSlideHdParent: true,
        slideHdParentName: '',
        slideHdParentUrl:'',
        slideHdName: '',
        hdTitle: '',
        hdName: '',
        pageCont: '',
        active: 0,
        slideHd: {
          title: '新闻动态',
        },
        slideList: [],
        pageSize: 10,
        pageNum: 1,
        total: 0,
        listData: [],
        listId: '',
        indexImg: '',
        noempty:false,
        applicationUuid:'',
        lang: 'zh-CN',
      }
    },
    filters: {
    ellipsis: function(value) {
      if (!value) return "";
      if (value.length > 120) {
        return value.slice(0, 120) + "...";
      }
      return value;
    },
    ellipsis2: function(value) {
      if (!value) return "";
      if (value.length > 45) {
        return value.slice(0, 45) + "...";
      }
      return value;
    },
  }, 
    mounted() {
      var _this = this
      _this.active = _this.$route.params.id
      _this.listId = _this.$route.params.id
      // let lang = getCookie('lang')
      let lang = localStorage.getItem('lang');
      if( lang == 'en-US'){
        this.applicationUuid = this.webIdEn
      }
      else {
        this.applicationUuid = this.webId
      }
      // _this.getChildChannel()
      _this.getChannelInfoNew()
      _this.articles()
    },
    methods: {
      menuShow(){
        this.isMenuShow = !this.isMenuShow
      },
      // 查询二级栏目
      getChildChannel(id) {
        var _this = this
        let data = {
          applicationUuid: this.applicationUuid,
          parentUuid: id
        }
        getChildChannel(data).then(res => {
          const {
            code,
            data
          } = res
          if (code === 1 && data) {
            _this.slideList = data
            if (_this.slideList.length) {
              _this.viewSlideHdParent = true
              let url = ''
              if (_this.slideList[0].catalog === 1) {
                url = `/listPage/${_this.slideList[0].entTChannelUuid}`
              } else if (_this.slideList[0].catalog === 2) {
                url = `/list/${_this.slideList[0].entTChannelUuid}`
              } else {
                url = `/list/${_this.slideList[0].entTChannelUuid}`
              }
              _this.slideHdParentUrl = url
            } else {
              _this.viewSlideHdParent = false
            }
          }
        })
      },
      getChannelInfoNew() {
        var _this = this
        let data = {
          channelUuid: _this.$route.params.id,
          applicationUuid: this.applicationUuid,
        }
        getChannelInfoNew(data).then(res => {
          const { code, data } = res
          if (code === 1 && data) {
            _this.slideHdName = data.name
            if (data.parentUuid == "0") {
              _this.slideHdTitle = data.name
              _this.viewSlideHdParent = false
              if(data.fileName && data.fileName !== ''){
                _this.indexImg = data.fileName
              }
              else{
                _this.indexImg = require('../assets/image/partyBuilding.jpg')
              }
              return
            }

            _this.getChildChannel(data.parentUuid)

            let params = {
              channelUuid: data.parentUuid,
              applicationUuid: this.applicationUuid,
            }
            getChannelInfoNew(params).then(res => {
              const { code, data } = res
              if (code === 1 && data) {
                _this.slideHdParentName = data.name
                _this.slideHdTitle = data.name
                if(data.fileName && data.fileName !== ''){
                  _this.indexImg = data.fileName
                }
                else{
                  _this.indexImg = require('../assets/image/partyBuilding.jpg')
                }
              }
            })
          }
        })
      },
      articles() {
        var _this = this
        let data = {
          channelUuid: _this.$route.params.id,
          entTApplicationUuid: this.applicationUuid,
          pageNum: _this.pageNum,
          pageSize: _this.pageSize
        }
        articles(data).then(res => {
          const {
            code,
            data
          } = res
          if (code === 1 && data) {
            _this.listData = data.rows
            _this.total = data.totalRows
          }
          else{
            this.noempty = true
          }
        })
      },
      currentChange(e) {
        // console.log('e :>> ', e);
        var _this = this
        _this.pageNum = e
        _this.articles()
      }
    }
  }
</script>
<style>
/* CDN 服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
  font-family: 'iconfont';  /* Project id 3165120 */
  src: url('//at.alicdn.com/t/c/font_3165120_j24ufv6zcfe.woff2?t=1661313604254') format('woff2'),
       url('//at.alicdn.com/t/c/font_3165120_j24ufv6zcfe.woff?t=1661313604254') format('woff'),
       url('//at.alicdn.com/t/c/font_3165120_j24ufv6zcfe.ttf?t=1661313604254') format('truetype');
}
.iconfont {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.1px;
  -moz-osx-font-smoothing: grayscale;
}
.listThird .image {
  width: 100%;
  height: 417px;
  overflow: hidden;
  min-width: 1200px;
  max-height: 330px;
  display: flex;
  margin: 0 auto;
  border: none;
  position: relative;
  z-index: 99;
}
.layout {
  width: 1200px;
  margin: 0 auto;
}
.contentlistThird{
  min-width: 1200px;
  min-height: 300px;
  /* display: flex; */
  margin: 20px 0 30px;
}
.left{
  width: 240px;
}
.listMainRight{
  width: 940px;
  padding: 0 0 20px 20px;
  overflow: hidden;
}
.listHd {
  height: 50px;
  display: flex;
}
.listHdGrop {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333;
  opacity: .6;
}
.listThird .main {
  width: 100%;
  height: 100%;
}
.listThird .main dl {
  display: flex;
  border-bottom: 2px solid #ddd;
  margin-bottom: 20px;
  min-height: 128px;
}
dl dt {
  text-align: center;
  margin-right: 50px;
  margin-top: 12px;
}
dl dt span {
  font-family: Microsoft YaHei;
  font-weight: 400;
}
dl dt span {
  width: 44px;
  height: 31px;
  font-size: 40px;
  color: #c6c6c6;
}
dl dt p {
  width: 80px;
  line-height: 15px;
  font-size: 20px;
  color: #333;
  padding: 10px 0 0;
}
dl dt p, dl dt span {
  font-family: Microsoft YaHei;
  font-weight: 400;
}
dd, dl, ol, p, ul {
  margin: 0;
}
.main_right_top {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  align-items: center;
  width: 1070px;
}
.main_right_top p {
  width: 900px;
  border-left: 3px solid #0a71c2;
  height: 19px;
  line-height: 19px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 5px;
}
.main_right_top p a {
  color: #333;
}
.main_right_top span {
  height: 5px;
  font-size: 30px;
  font-family: Microsoft YaHei;
  font-weight: 700;
  margin-top: -40px;
  color: #0a71c2;
  float: right;
  display: inline-block;
}
.main_right_text {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333;
}

.main_right_text {
  line-height: 32px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  margin-bottom: 20px;
}
.main_right_foot {
  height: 15px;
  opacity: .6;
  padding-bottom: 20px;
}

.main_right_foot, .main_right_text {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333;
}
.main_right_foot, .main_right_text {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333;
}
.main_right_top a:hover p {
  color: #0a71c2;
  cursor: pointer;
}
.main_right_top a:hover span {
  color: #0a71c2;
  cursor: pointer;
}
</style>